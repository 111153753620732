import { AppConfig } from "../Config"

export interface TagManagerConfigI {
  tagManagerId: string
  tagManagerEnv: string
  tagManagerEnvAuth: string
}

export const TagManagerConfig: TagManagerConfigI = {
  tagManagerId: AppConfig.google.tagManagerId,
  tagManagerEnv: AppConfig.google.tagManagerEnv,
  tagManagerEnvAuth: AppConfig.google.tagManagerEnvAuth
}
