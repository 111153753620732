import { KlaviyoConfiguration } from "./klaviyo"

export const isDevMode = process.env.NODE_ENV === "development"
export const isProdMode = process.env.NODE_ENV !== "development"

export interface Config {
  environment: string
  isDevMode: boolean
  isProdMode: boolean
  appRoot: string
  port: string
  recaptchaSiteKey: string
  sentry: {
    dsn: string
    environment: string
    release: string
  }
  commerceTools: {
    projectKey: string
    credentials: {
      clientId: string
      clientSecret: string
    }
    scopes: string[]
    apiUrl: string
    authUrl: string
    useRequestLogger: boolean
  }
  christmasPageEnabled: boolean
  adyen: {
    clientKey: string
    environment: string
  }
  borgun: {
    publicKey: string
    paymentJsUrl: string
  }
  prismic: {
    repository: string
    accessToken: string
    clientId: string
    clientSecret: string
  }
  netGiro: {
    redirectUrl: string
  }
  google: {
    tagManagerId: string
    tagManagerEnv: string
    tagManagerEnvAuth: string
    mapKey: string
  }
  appRise: {
    url: string
    secret: string
  }
  featureFlags: {
    sample: boolean
    adyen: boolean
    applePay: boolean
    vatId: boolean
    disablePrismicNav: boolean
  }
  rakuten: {
    trackingKey: string
    affiliateNetworkID: string
  }
  exchangeRateApi: {
    key: string
  }
  applePay: {
    merchantIdentifier: string
    initiativeContext: string
  }
  truefit: {
    stores: string[]
  }
  klaviyo: KlaviyoConfiguration
}

export const required = (
  value: string | undefined,
  configKey: string
): string => {
  if (!!value) {
    return value
  } else {
    throw Error(`Required configuration value is missing: ${configKey}`)
  }
}

export const requiredWithDevValue = (
  value: string | undefined,
  configKey: string,
  devValue: string
): string => {
  if (!!value) {
    return value
  } else if (isDevMode) {
    return devValue
  } else {
    throw Error(`Required configuration value is missing: ${configKey}`)
  }
}

export const parseScope = (value: string) => {
  return value
    .split(RegExp(/;|\s/g))
    .map(it => it.trim())
    .filter(it => !!it)
}

export const AppConfig: Config = {
  isDevMode,
  isProdMode,
  appRoot: required(process.env.APP_ROOT, "APP_ROOT"),
  appRise: {
    secret: required(process.env.APPRISE_API_SECRET, "APPRISE_API_SECRET"),
    url: required(process.env.APPRISE_API_URL, "APPRISE_API_URL")
  },
  environment: required(process.env.ENVIRONMENT, "ENVIRONMENT"),
  port: required(process.env.PORT, "PORT"),
  recaptchaSiteKey: required(
    process.env.RECAPTCHA_SITE_KEY,
    "RECAPTCHA_SITE_KEY"
  ),
  sentry: {
    dsn: required(process.env.SENTRY_DSN, "SENTRY_DSN"),
    environment: required(process.env.SENTRY_ENVIRONMENT, "SENTRY_ENVIRONMENT"),
    release: required(process.env.SENTRY_RELEASE, "SENTRY_RELEASE")
  },
  commerceTools: {
    projectKey: required(process.env.CTP_PROJECT_KEY, "CTP_PROJECT_KEY"),
    credentials: {
      clientId: required(process.env.CTP_CLIENT_ID, "CTP_CLIENT_ID"),
      clientSecret: required(process.env.CTP_CLIENT_SECRET, "CTP_CLIENT_SECRET")
    },
    scopes: parseScope(required(process.env.CTP_SCOPE, "CTP_SCOPE")),
    apiUrl: required(process.env.CTP_API_URL, "CTP_API_URL"),
    authUrl: required(process.env.CTP_AUTH_URL, "CTP_AUTH_URL"),
    useRequestLogger: process.env.CTP_LOG_REQUESTS === "true"
  },
  christmasPageEnabled: process.env.CHRISTMAS_PROMO_PAGE === "true",
  adyen: {
    clientKey: required(process.env.ADYEN_CLIENT_KEY, "ADYEN_CLIENT_KEY"),
    environment: required(process.env.ADYEN_ENVIRONMENT, "ADYEN_ENVIRONMENT")
  },
  borgun: {
    publicKey: required(process.env.BORGUN_PUBLIC_KEY, "BORGUN_PUBLIC_KEY"),
    paymentJsUrl: required(
      process.env.BORGUN_PAYMENT_JS_URL,
      "BORGUN_PAYMENT_JS_URL"
    )
  },
  netGiro: {
    redirectUrl: requiredWithDevValue(
      process.env.NETGIRO_URL,
      "NETGIRO_URL",
      "https://test.netgiro.is/securepay"
    )
  },
  prismic: {
    repository: required(process.env.PRISMIC_REPOSITORY, "PRISMIC_REPOSITORY"),
    accessToken: required(
      process.env.PRISMIC_ACCESS_TOKEN,
      "PRISMIC_ACCESS_TOKEN"
    ),
    clientId: required(process.env.PRISMIC_CLIENT_ID, "PRISMIC_CLIENT_ID"),
    clientSecret: required(
      process.env.PRISMIC_CLIENT_SECRET,
      "PRISMIC_CLIENT_SECRET"
    )
  },
  google: {
    tagManagerId: required(
      process.env.GOOGLE_TAG_MANAGER_ID,
      "GOOGLE_TAG_MANAGER_ID"
    ),
    tagManagerEnv: required(
      process.env.GOOGLE_TAG_MANAGER_ENV,
      "GOOGLE_TAG_MANAGER_ENV"
    ),
    tagManagerEnvAuth: required(
      process.env.GOOGLE_TAG_MANAGER_ENV_AUTH,
      "GOOGLE_TAG_MANAGER_ENV_AUTH"
    ),
    mapKey: required(process.env.GOOGLE_MAPS_API_KEY, "GOOGLE_MAPS_API_KEY")
  },
  featureFlags: {
    sample: true,
    disablePrismicNav:
      requiredWithDevValue(
        process.env.DISABLE_PRISMIC_NAV,
        "DISABLE_PRISMIC_NAV",
        "false"
      ) === "true",
    applePay:
      requiredWithDevValue(
        process.env.USE_APPLE_PAY,
        "USE_APPLE_PAY",
        "true"
      ) === "true",
    adyen:
      requiredWithDevValue(process.env.USE_ADYEN, "USE_ADYEN", "true") ===
      "true",
    vatId:
      requiredWithDevValue(process.env.USE_VAT_ID, "USE_VAT_ID", "false") ===
      "true"
  },
  rakuten: {
    trackingKey: required(
      process.env.RAKUTEN_TRACKING_KEY,
      "RAKUTEN_TRACKING_KEY"
    ),
    affiliateNetworkID: required(
      process.env.RAKUTEN_AFFILIATE_NETWORK_ID,
      "RAKUTEN_AFFILIATE_NETWORK_ID"
    )
  },
  exchangeRateApi: {
    key: required(process.env.EXCHANGE_RATES_API_KEY, "EXCHANGE_RATES_API_KEY")
  },
  applePay: {
    merchantIdentifier: required(
      process.env.APPLE_MERCHANT_IDENTIFIER,
      "APPLE_MERCHANT_IDENTIFIER"
    ),
    initiativeContext: required(
      process.env.APPLE_INITIATIVE_CONTEXT,
      "APPLE_INITIATIVE_CONTEXT"
    )
  },
  truefit: {
    stores: requiredWithDevValue(
      process.env.TRUEFIT_ENABLED_STORES,
      "TRUEFIT_ENABLED_STORES",
      "DK;EU;GB;IS;US"
    ).split(";")
  },
  klaviyo: {
    newsletterSignupForm: {
      en: requiredWithDevValue(
        process.env.KLAVIYO_EN_NEWSLETTER_FORM,
        "KLAVIYO_EN_NEWSLETTER_FORM",
        "klaviyo-form-UQQfbk"
      ),
      is: requiredWithDevValue(
        process.env.KLAVIYO_IS_NEWSLETTER_FORM,
        "KLAVIYO_IS_NEWSLETTER_FORM",
        "klaviyo-form-UQQfbk"
      )
    },
    privateApiKey: required(
      process.env.KLAVIYO_PRIVATE_API_KEY,
      "KLAVIYO_PRIVATE_API_KEY"
    )
  }
}

export const webNextPrismicApiUrl = (...path: string[]) => {
  return appUrl(`api/prismic/${path.join("/")}`)
}

export const tooltipDataUrl = (...path: string[]) => {
  return appUrl(`api/tooltips/${path.join("/")}`)
}

export const pdpDataUrl = (...path: string[]) => {
  return appUrl(`api/product/${path.join("/")}`)
}

export const paymentApiUrl = (...path: string[]) => {
  // return appUrl(`/api/v1/payment/${path.join("/")}`)
  return appUrl(`api/v1/payment/${path.join("/")}`)
}

export const productApiUrl = (...path: string[]) => {
  return appUrl(`api/v1/products/${path.join("/")}`)
}

export const cartApiUrl = (...path: string[]) => {
  return appUrl(`api/v1/cart/${path.join("/")}`)
}

export const appUrl = (...path: string[]) => {
  // https://www.dev.23west.is
  return `/${path.join("/").replace("//", "/")}`
}
